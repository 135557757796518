<template>
  <div class="add-new-site">
    <b-modal :active.sync="show_table_users" class="popup-users-list" :can-cancel="false">
      <div class="popup-users-list-wrapper">
        <div class="close-popup" @click="show_table_users = false">
          <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z" fill="#2C3236">
            </path>
          </svg>
        </div>
        <b-table 
          :data="users_array" 
          :mobile-cards="false" 
          :paginated="true"
          :per-page="10"
          hoverable>
          <template slot-scope="data">
            <b-table-column label="Имя пользователя" :searchable="'true'">
              <div>
                {{ data.row.username }}
              </div>
            </b-table-column>
            <b-table-column :centered="true">
              <div>
                <b-button type="is-primary" v-if="add_new_site.user_id !== data.row.id" outlined @click="selectedUsers(data.row)">
                  Выбрать
                </b-button>
              </div>
            </b-table-column>
          </template>
        </b-table>
      </div>
    </b-modal>
    <div class="add-new-site-header">
      <b-button class="request-button" type="is-primary" @click="$router.push({name: 'Sites'})">
        {{ $t('back') }}
      </b-button>
    </div>
    <div class="alert animated none" :class="alert.show ? 'fadeInRight' : ''" v-show="alert.show">
      <div class="alert-close" @click="alert.show = !alert.show">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.88463 5.0001L9.81717 1.06756C10.0615 0.823448 10.0615 0.427359 9.81717 0.183243C9.57285 -0.0610811 9.17718 -0.0610811 8.93285 0.183243L5.00031 4.11578L1.06756 0.183243C0.82324 -0.0610811 0.427568 -0.0610811 0.183243 0.183243C-0.0610811 0.427359 -0.0610811 0.823448 0.183243 1.06756L4.11599 5.0001L0.183243 8.93264C-0.0610811 9.17676 -0.0610811 9.57285 0.183243 9.81697C0.305406 9.93892 0.465509 10 0.625404 10C0.785299 10 0.945402 9.93892 1.06756 9.81676L5.00031 5.88422L8.93285 9.81676C9.05501 9.93892 9.21512 10 9.37501 10C9.53491 10 9.69501 9.93892 9.81717 9.81676C10.0615 9.57264 10.0615 9.17655 9.81717 8.93244L5.88463 5.0001Z" fill="#ffffff"/>
        </svg>
      </div>
      {{ alert.text }}
    </div>
    <div class="add-new-site-block">
      <div class="loading" v-show="loading">
        <b-loading :is-full-page="false" :active.sync="loading" :can-cancel="false"></b-loading>
      </div>
      <b-field :label="'Домен сайта:'" :type="error_add_new_site.domain.type" :message="error_add_new_site.domain.message">
        <b-input :placeholder="$t('users.enter_username')" v-model="add_new_site.domain"></b-input>
      </b-field>
      <b-field :label="'Ссылка на фид:'" :type="error_add_new_site.url_feed.type" :message="error_add_new_site.url_feed.message">
        <b-input :placeholder="$t('users.enter_username')" v-model="add_new_site.feed"></b-input>
      </b-field>
      <div class="selected-user">
      <div class="label">Имя пользователя:</div>
        <div class="name-users">
          <b-field :type="error_add_new_site.user_id.type" :message="error_add_new_site.user_id.message">
            <div v-if="add_new_site.user_id">
              {{ name_selected_user }}
            </div>
          </b-field>
          </div>
      </div>
      <b-button class="request-buttossn" type="is-primary" @click="showUsersControlPanel" outlined>
        {{ !add_new_site.user_id ? 'Выбрать пользователя' : 'Выбрать другого пользователя' }}
      </b-button>
      <b-button class="request-buttossn" type="is-primary" @click="clickAddNewSite">
        Добавить 
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddNewSite',
  data () {
    return {
      loading: false,
      name_selected_user: null,
      users_array: null,
      show_table_users: false,
      error_add_new_site: {
        domain: {
          type: '',
          message: '',
          check: false
        },
        url_feed: {
          type: '',
          message: '',
          check: false
        },
        user_id: {
          type: '',
          message: '',
          check: false
        }
      },
      add_new_site: {
        domain: '',
        feed: '',
        user_id: '',
        language: this.$store.state.language
      },
      alert: {
        show: false,
        text: ''
      },
    }
  },
  methods: {
    selectedUsers (data) {
      this.add_new_site.user_id = data.id
      this.show_table_users = false
      this.name_selected_user = data.username
      this.error_add_new_site.user_id.check = false
      this.error_add_new_site.user_id.message = ''
      this.error_add_new_site.user_id.type = ''
    },
    showUsersControlPanel () {
      if (this.$store.getters.all_users) {
        let string = []
        for (var key in this.$store.getters.all_users) {
          string.push(this.$store.getters.all_users[key])
        }
        this.loading = false
        string = JSON.stringify(string)
        this.users_array = JSON.parse(string)
        this.show_table_users = true
      } else {
        this.loading = true
        this.$store.dispatch('get_users', {
          roles: [],
          vue: this,
          callback: this.showTableUsers,
          callbackerror: this.errorShowUsersControlPanel
        })
      }
    },
    showTableUsers (users) {
      this.loading = false
      this.show_table_users = true
      let transformArr = []
      
      for (var key in users.response) {
        transformArr[users.response[key].id] = users.response[key]
      }

      this.$store.commit('response_all_user', transformArr)

      let stringify = JSON.stringify(users.response)
      this.users_array = JSON.parse(stringify)
    },
    errorShowUsersControlPanel (error) {
      this.loading = false
      console.log(error)
      this.alertNotification('Ошибка запроса данных')
    },
    clickAddNewSite() {
      if (!this.add_new_site.domain) {
        this.error_add_new_site.domain.type = 'is-danger'
        this.error_add_new_site.domain.message = 'Обязательно поле для заполнения'
        this.error_add_new_site.domain.check = false
      } else {
        this.error_add_new_site.domain.type = ''
        this.error_add_new_site.domain.message = ''
        this.error_add_new_site.domain.check = true
      }

      if (!this.add_new_site.feed) {
        this.error_add_new_site.url_feed.type = 'is-danger'
        this.error_add_new_site.url_feed.message = 'Обязательно поле для заполнения'
        this.error_add_new_site.url_feed.check = false
      } else {
        this.error_add_new_site.url_feed.type = ''
        this.error_add_new_site.url_feed.message = ''
        this.error_add_new_site.url_feed.check = true
      }

      if (!this.add_new_site.user_id) {
        this.error_add_new_site.user_id.type = 'is-danger'
        this.error_add_new_site.user_id.message = 'Необходимо выбрать пользователя'
        this.error_add_new_site.user_id.check = false
      } else {
        this.error_add_new_site.user_id.type = ''
        this.error_add_new_site.user_id.message = ''
        this.error_add_new_site.user_id.check = true
      }


      if (this.error_add_new_site.url_feed.check && this.error_add_new_site.domain.check && this.error_add_new_site.user_id.check) {
        this.AddNewSite()
      } else {
        this.alertNotification('Валидация не пройдена')
      }
    },
    AddNewSite () {
      this.loading = true
      let request_body = this.add_new_site 
      this.$http.post(`${this.$store.state.admin_api_url}/sites`, request_body, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': this.$store.state.language,
          'Auth-Token': this.$store.state.auth.token
        }
      })
        .then(function(response) {
          if(response.status === 200) {
            this.loading = false
            this.alertNotification('Сайт добавлен')
            this.add_new_site = {
              domain: null,
              feed: null,
              user_id: null,
              language: this.$store.state.language
            }
            this.name_selected_user = null
            this.$store.commit('response_all_user', null)
          }
        },this)
        .catch(function(reject) {
          this.loading = false
          console.log('AddNewSite, Error -', reject)
          this.alertNotification('Ошибка добавления сайта')
        }.bind(this))
    },
    alertNotification(text) {
      this.alert.show = true
      this.alert.text = text

      setTimeout(() => {
        this.alert.show = false
      }, 3500), this;
    },
  }
}
</script>

<style lang="scss">
.add-new-site {

  .popup-users-list {
    .popup-users-list-wrapper {
      position: relative;
      margin: auto;
      background: white;
      padding: 30px;
      width: 700px;

      .close-popup {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .level {
      .pagination-previous {
        span {
          border-left: 3px solid #7957d5;
          border-top: 3px solid #7957d5;
          transform: rotate(-45deg);
          height: 15px;
          width: 15px;
          margin-left: 5px;
          border-radius: 2px;
        }
      }
      .pagination-next {
        span {
          border-left: 3px solid #7957d5;
          border-top: 3px solid #7957d5;
          transform: rotate(135deg);
          height: 15px;
          width: 15px;
          margin-right: 5px;
          border-radius: 2px;
        }
      }
    }
  }

  .add-new-site-block {
    margin-top: 20px;
    border-radius: 4px;
    padding: 15px;
    border: 1px solid #dbdbdb;
    display: flex;
    flex-direction: column;
    position: relative;

    .field {
      width: 50%;
    }

    button {
      max-width: 270px;
      margin: 10px 0;
    }

    .loading {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 100;
    }
  }

  .selected-user {
    margin-bottom: 30px;

    .name-users {
      height: 36px;
      border: 1px solid #dbdbdb;
      width: 50%;
      padding: 5px 10px;
      border-radius: 4px;
    }
  }

  .alert {
    z-index: 41;
    position: fixed;
    top: 100px;
    right: 50px;
    border: 1px solid #7957d5;
    padding: 20px 25px;
    border-radius: 4px;
    background: #7957d5;
    color: #fff;

    &-close {
      position: absolute;
      right: 3px;
      top: 3px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }
}
</style>